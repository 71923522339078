<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <h2>{{ $t('usersList') }}</h2>
      <grid-view-table
        :id="'users'"
        :columns="columns"
        :extended-filter-schema="extendedFilterSchema"
        :path="'users'"
        :add-route="'AddUser'"
        :update-route="'UpdateUser'"
        :add-text="$t('addUser')"
        :extra-data="extraData"
      />
    </div>
  </div>
</template>

<script>
  import GridViewTable from '../../components/GridViewTable';
  import { columns } from './grid-views/users';
  import { schema as extendedFilterSchema } from './filter-forms-schemas/user';

  export default {
    name: 'UsersList',
    components: {
      GridViewTable,
    },
    data: () => {
      return {
        columns,
        extendedFilterSchema,
        extraData: {
          alertsPath: 'users-alerts',
        }
      }
    }
  }
</script>
