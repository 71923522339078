import { validators } from "vue-form-generator";
import { uniquerUsernameValidator } from "../validations/uniqueUsername";
import i18n from "@/plugins/i18n";

export const schema = {
  fields: [
    {
      type: "input",
      inputType: "text",
      label: "userLogin",
      model: "username",
      required: false,
      placeholder: "userLogin",
      max: 128,
      min: 4,
      validator: [
        validators.string.locale({
          textTooSmall: i18n.t("userLoginMustBeMin{1}"),
          textTooBig: i18n.t("userLoginMustBeLess{1}"),
        }),
      ]
    },
    {
      type: "select",
      label: "userRole",
      model: "role",
      required: false,
      selectOptions: {
        noneSelectedText: i18n.t("userChooseRole"),
      },
      values: [
        { name: i18n.t("Administrator"), id: "admin" },
        { name: i18n.t("Moderator"), id: "publisher" },
        { name: i18n.t("Farmer"), id: "farmer" },
        { name: i18n.t("Customer"), id: "customer" },
      ],
    },
    {
      type: "select",
      label: "userStatus",
      model: "status",
      required: false,
      selectOptions: {
        noneSelectedText: i18n.t("userChooseStatus"),
      },
      values: [
        { name: i18n.t("Active"), id: 1 },
        { name: i18n.t("Hidden"), id: 0 },
      ],
    },
    {
      type: "input",
      inputType: "text",
      label: "userName",
      model: "name",
      required: false,
      placeholder: "userName",
      max: 255,
      validator: [
        validators.string.locale({
          textTooBig: i18n.t("userNameMustBeLess{1}")
        }),
      ]
    },
    {
      type: "input",
      inputType: "text",
      label: "userTown",
      model: "town",
      required: false,
      placeholder: "userTown",
      max: 255,
      validator: [
        validators.string.locale({
          textTooBig: i18n.t("userTownMustBeLess{1}")
        }),
      ]
    },
    {
      type: "input",
      inputType: "text",
      label: "userAddress",
      model: "address",
      required: false,
      placeholder: "userAddress",
      max: 255,
      validator: [
        validators.string.locale({
          textTooBig: i18n.t("userAddressMustBeLess{1}")
        }),
      ]
    },
    {
      type: "input",
      inputType: "email",
      label: "Email",
      model: "email",
      required: false,
      placeholder: "Email",
      max: 255,
      validator: [
        validators.email.locale({
          textTooBig: i18n.t("userEmailMustBeLess{1}"),
          invalidEmail: i18n.t("userEmailIncorrect"),
        }),
      ]
    },
    {
      type: "input",
      inputType: "phone",
      label: "userPhone",
      model: "phone",
      required: false,
      placeholder: "userPhone",
      max: 255,
      validator: [
        validators.string.locale({
          textTooBig: i18n.t("userPhoneMustBeLess{1}"),
        }),
      ]
    },
    {
      type: "textArea",
      label: "userProfile",
      model: "profile",
      required: false,
      placeholder: "userProfile",
      max: 2048,
      validator: [
        validators.string.locale({
          textTooBig: i18n.t("userProfileMustBeLess{1}")
        }),
      ]
    },
  ]
};
