import moment from 'moment';
import { actions } from "../../../components/grid-views/standart-actions-without-view";
import { statusActions } from "../../../components/grid-views/status-actions";
import {APIService} from "../../../services/api";
import i18n from "@/plugins/i18n";

export const columns = [
  {
    label: 'userName',
    field: 'name',
    width: '13%',
    filterOptions: {
      enabled: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'userRole',
    field: 'role',
    width: '10%',
    formatFn: (value) => {
      if (value === 'customer') {
        return i18n.t('Customer');
      }
      if (value === 'farmer') {
        return i18n.t('Farmer');
      }
      if (value === 'admin') {
        return i18n.t('Administrator');
      }
      if (value === 'publisher') {
        return i18n.t('Moderator');
      }
      return '';
    },
    filterOptions: {
      enabled: true,
      placeholder: ' ',
      filterValue: '',
      filterDropdownItems: [
        { value: 'admin', text: i18n.t('Administrator') },
        { value: 'publisher', text: i18n.t('Moderator') },
        { value: 'farmer', text: i18n.t('Farmer') },
        { value: 'customer', text: i18n.t('Customer') },
      ],
    },
  },
  {
    label: 'userAddress',
    field: 'address',
    width: '30%',
    filterOptions: {
      enabled: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'userPhone',
    field: 'phone',
    width: '10%',
    filterOptions: {
      enabled: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'userEmail',
    field: 'email',
    width: '10%',
    filterOptions: {
      enabled: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'userDateCreate',
    field: 'createdAt',
    width: '10%',
    formatFn: (value) => {
      return moment(value).format('DD.MM.YYYY');;
    },
    filterOptions: {
      enabled: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: '',
    field: 'actions',
    width: '13%',
    sortable: false,
    actions: [
      ...statusActions,
      {
        type: 'customer',
        label: '',
        class: 'fa fa-user',
        action: {
          type: 'callback',
          callbackFunction: async (list, data, actionParams, refresh, eventBus) => {
            data.role = 'farmer';
            try {
              await APIService.put(APIService.getRestFullEntityUrl(actionParams.url, data), data);
              eventBus.$emit('alert', {
                type: 'success',
                text: i18n.t('userRoleWasSuccessfullySaved'),
              });
            } catch (e) {
              eventBus.$emit('alert', {
                type: 'danger',
                text: i18n.t('errorOnUserRoleSaving'),
              });
            }
            refresh();
          },
          visibilityResolver: (data) => {
            return data.role === 'customer';
          },
        },
      },
      {
        type: 'farmer',
        label: '',
        class: 'fa fa-paw',
        action: {
          type: 'callback',
          callbackFunction: async (list, data, actionParams, refresh, eventBus) => {
            data.role = 'customer';
            try {
              await APIService.put(APIService.getRestFullEntityUrl(actionParams.url, data), data);
              eventBus.$emit('alert', {
                type: 'success',
                text: i18n.t('userRoleWasSuccessfullySaved'),
              });
            } catch (e) {
              eventBus.$emit('alert', {
                type: 'danger',
                text: i18n.t('errorOnUserRoleSaving'),
              });
            }
            refresh();
          },
          visibilityResolver: (data) => {
            return data.role === 'farmer';
          },
        },
      },
      {
        type: 'alert',
        label: '',
        class: 'fa fa-thumbs-up',
        action: {
          type: 'route',
          name: 'AddUserAlert',
          paramsResolver: (data) => {
            return {id: data.id}
          },
          visibilityResolver: (data) => {
            return (data.role==='customer' || data.role==='farmer') && data.alerts.length === 0;
          },
        },
      },
      {
        type: 'remove-alert',
        label: '',
        class: 'fa fa-exclamation-triangle',
        action: {
          type: 'callback',
          callbackFunction: async (list, data, actionParams, refresh, eventBus) => {
            const isDel = confirm(i18n.t('confirmDeleteUserFromBlackList'));
            if (isDel) {
              const url = `${actionParams.host}${actionParams.extraData.alertsPath}/${data.alerts[0].id}`;
              try {
                await APIService.delete(url);
                eventBus.$emit('alert', {
                  type: 'success',
                  text: i18n.t('dataWasSuccessfullyDeleted'),
                });
              } catch (e) {
                eventBus.$emit('alert', {
                  type: 'danger',
                  text: i18n.t('errorOnDataDelete'),
                });
              }
              refresh();
            }
          },
          visibilityResolver: (data) => {
            return (data.role==='customer' || data.role==='farmer') && data.alerts.length > 0;
          },
        },
      },
      ...actions,
    ]
  },
]
